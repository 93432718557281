import React from "react";
import { useTranslation } from "react-i18next";
import FilesTable from "../../components/filesTable";
import { typeFiles } from "../../constants/enum";

const MockInterview = () => {
  const { t } = useTranslation();
  const files = [
    {
      name: "material.instructions",
      description: "material.instructionsDescription",
      url: "/download/GeneralDocuments/Instructivo página web WORLDWIDE.pdf",
    },
    {
      name: "Job interview tips",
      description:
        "Este video encontrará los mejores tips para preparar tu próxima JOB INTERVIEW",
      url: "https://www.youtube.com/watch?v=LxEXzfq9nRk",
      type: typeFiles.video,
    },
    {
      name: "Job Position Description Guide for Participants",
      description: "help.mockInterviewGuide",
      url: "/download/Job Position Description Guide.pdf",
    },
    {
      name: "Be prepared for your Job interview",
      description: "help.mockInterviewPreparation",
      url: "/download/Job Interview II.pdf",
    },
  ];
  return (
    <>
      <h2 className="title">{t("material.interviewWWCE")}</h2>
      <FilesTable files={files} />
    </>
  );
};

export default MockInterview;

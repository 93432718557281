import React from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { getUserInfo } from "../../services/authService";
import { callApi, signConditions } from "../../services/apiService";
import Button from "../buttons/button";
import i18next from "../../i18n";
import { useForm } from "react-hook-form";
import { AiOutlineClear } from "react-icons/ai";
import { NotificationManager } from "react-notifications";

const styles = {
  border: "0.0625rem solid #9c9c9c",
  borderRadius: "0.25rem",
};

const SignConditionsCanvas = ({ setSignBtn, age, onClose, setViewPath, courseId }) => {
  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const validations = {};

  const SignDocumentPDF = async () => {
    if (watch("nameParent") == "" && age < 21) {
      NotificationManager.warning(i18next.t("files.validations.conditions"));
      return;
    }
    const uInfo = getUserInfo();

    const image1 = await canvas1.current.exportImage("png");
    var image2 = "";
    var parentName = "";
    if (age < 21) {
      image2 = await canvas2.current.exportImage("png");
      parentName = watch("nameParent");
    }

    callApi(() => {
      const currentDate = Date.now();
      var submitData = {
        currentDate: new Date(currentDate),
        id: parseInt(uInfo.id),
        signTraveler: image1,
        signParent: image2,
        parentName: parentName,
        courseId : courseId
      };
      signConditions(submitData);
      NotificationManager.success(i18next.t("form.success"));
      setViewPath("WorldWideFiles/" + submitData.id + "/generalConditions.pdf");
      onClose();
    });
  };

  const canvas1 = React.createRef();
  const canvas2 = React.createRef();

  return (
    <div>
      <div className="column-center sign-field-fields">
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="sign-field"
        >
          <ReactSketchCanvas
            ref={canvas1}
            strokeWidth={5}
            strokeColor="black"
          />
          <Button
            label={<AiOutlineClear />}
            onClick={() => {
              canvas1.current.clearCanvas();
            }}
          />
        </div>
        {age < 21 && (
          <>
            <label>
              {i18next.t("form.guardianName")}:
              <span className="text-danger"> *</span>
            </label>
            <div
              className="sign-field-botons"
            >
            <input
              placeholder={i18next.t("form.guardianNameLabel")}
              className="form-control mb-2"
              name="nameParent"
              {...register("nameParent", validations.nameParent)}
            />
            </div>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="sign-field"
            >
              <ReactSketchCanvas
                ref={canvas2}
                strokeWidth={5}
                strokeColor="black"
              />
              <Button
                label={<AiOutlineClear />}
                onClick={() => {
                  canvas2.current.clearCanvas();
                }}
              />
            </div>
          </>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          className="sign-field-botons"
        >
          <Button
            size="btn-s secondary"
            label={i18next.t("main.close")}
            onClick={() => onClose()}
          />
          <Button
            size="btn-s"
            label={i18next.t("main.sign")}
            onClick={() => {
              SignDocumentPDF();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignConditionsCanvas;

import React from "react";
import { useTranslation } from "react-i18next";
import VideoPlayer from "../../components/videoPlayer";
import FilesTable from "../../components/filesTable";

const General = () => {
  const { t } = useTranslation();
  const files = [
    {
      name: "material.instructions",
      description: "material.instructionsDescription",
      url: "/download/GeneralDocuments/Instructivo página web WORLDWIDE.pdf",
    },
  ];

  return (
    <>
      <h2 className="title">{t("material.general")}</h2>
      <VideoPlayer
        url="/videos/Worldwide 2024 HomeWeb.mp4"
        title={t("videos.formWorkAndTravel")}
      />
      <FilesTable files={files} />
    </>
  );
};

export default General;

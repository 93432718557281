import React from "react";
import { useTranslation } from "react-i18next";
import FilesTable from "../../components/filesTable";

const InterviewWork = () => {
  const { t } = useTranslation();
  const files = [
    {
      name: "material.instructions",
      description: "material.instructionsDescription",
      url: "/download/GeneralDocuments/Instructivo página web WORLDWIDE.pdf",
    },
  ];

  return (
    <>
      <h2 className="title">{t("material.interviewWork")}</h2>
      <FilesTable files={files} />
    </>
  );
};

export default InterviewWork;

import React, { useEffect, useState } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { getUserInfo } from "../../../services/authService";
import {
  callApi,
  getHealthConditionsFile,
  signHealthConditions,
} from "../../../services/apiService";
import Button from "../../../components/buttons/button";
import i18next from "../../../i18n";
import { useForm } from "react-hook-form";
import { AiOutlineClear } from "react-icons/ai";
import { NotificationManager } from "react-notifications";

const CanvaSignRaw = ({ onClose, setSignPath, userId, open }) => {
  const [age, setAge] = useState(null);

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const validations = {};

  const ageResolve = (dateString) => {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    setAge(edad);
    return;
  };

  const loadData = (userId) => {
    if (userId === undefined || !open) return;
    callApi(
      () => getHealthConditionsFile(userId),
      (data) => {
        ageResolve(data.dateBirth);
        if (data.path != null) {
          setSignPath(data.path);
        }
      }
    );
  };

  useEffect(() => {
    if (!open) return;
    loadData(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const SignDocumentPDF = async () => {
    if (watch("nameParent") == "" && age < 21) {
      NotificationManager.warning(i18next.t("files.conditionsHealthRequested"));
      return;
    }
    const uInfo = getUserInfo();

    const image1 = await canvas1.current.exportImage("png");
    var image2 = "";
    var parentName = "";
    if (age < 21) {
      image2 = await canvas2.current.exportImage("png");
      parentName = watch("nameParent");
    }

    callApi(() => {
      const currentDate = Date.now();
      var submitData = {
        currentDate: new Date(currentDate),
        id: parseInt(uInfo.id),
        signTraveler: image1,
        signParent: image2,
        parentName: parentName,
      };
      signHealthConditions(submitData);
      NotificationManager.success(i18next.t("form.success"));
      setSignPath("WorldWideFiles/" + submitData.id + "/healthConditions.pdf");
      onClose();
    });
  };

  const canvas1 = React.createRef();
  const canvas2 = React.createRef();

  return (
    <div>
      <div className="column-center sign-field-fields">
        <label>
          {i18next.t("files.travelerSignature")}:
          <span className="text-danger"> *</span>
        </label>
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="sign-field"
        >
          <ReactSketchCanvas
            ref={canvas1}
            strokeWidth={5}
            strokeColor="black"
          />
          <Button
            label={<AiOutlineClear />}
            onClick={() => {
              canvas1.current.clearCanvas();
            }}
          />
        </div>
        {age < 21 && (
          <>
            <label>
              {i18next.t("form.guardianName")}:
              <span className="text-danger"> *</span>
            </label>
            <div className="sign-field-botons">
              <input
                placeholder={i18next.t("form.guardianNameLabel")}
                className="form-control mb-2"
                name="nameParent"
                {...register("nameParent", validations.nameParent)}
              />
            </div>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className="sign-field"
            >
              <ReactSketchCanvas
                ref={canvas2}
                strokeWidth={5}
                strokeColor="black"
              />
              <Button
                label={<AiOutlineClear />}
                onClick={() => {
                  canvas2.current.clearCanvas();
                }}
              />
            </div>
          </>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
          className="sign-field-botons"
        >
          <Button
            size="btn-s secondary"
            type="ww-btn-secondary"
            label={i18next.t("main.close")}
            onClick={() => onClose()}
          />
          <Button
            size="btn-s"
            label={i18next.t("main.sign")}
            onClick={() => {
              SignDocumentPDF();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CanvaSignRaw;

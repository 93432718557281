import React, { useEffect, useState } from "react";
import { AiFillEye } from "react-icons/ai";
import {
  callApi,
  uploadImages,
  changeState,
  getConditions,
} from "../../services/apiService";
import Modal from "@mui/material/Modal";
import MainContainer from "../../layouts/container/mainContainer";
import { FormProvider, useForm } from "react-hook-form";
import { NotificationManager } from "react-notifications";
import { getUserInfo } from "../../services/authService";
import { useTranslation } from "react-i18next";
import { FileTypeId, FormSteps, fileType } from "../../constants/enum";
import Button from "../../components/buttons/button";
import SignConditionsCanvas from "../../components/signComponent/signConditions";
import { BsCameraReelsFill } from "react-icons/bs";

const urlBase = process.env.REACT_APP_URL_BASE;
const urlApi = process.env.REACT_APP_API_URL;

const GeneralConditions = ({ setState, postulationState, programName }) => {
  const { t } = useTranslation();
  const isAndroid = /Android/i.test(navigator.userAgent);
  const [open, setOpen] = useState(false);
  const [viewPath, setViewPath] = useState("");
  const [previewView, setPreviewModal] = useState(false);
  const [signModal, setSignModal] = useState(false);
  const [document, setDocument] = useState([]);
  const [age, setAge] = useState(null);
  const [botonActivo, setBotonActivo] = useState(false);
  const [courseId, setCourseId] = useState(null);
  var userInfo = getUserInfo();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseSign = () => setSignModal(false);

  const ageResolve = (dateString) => {
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    setAge(edad);
    return;
  };

  const backForm = () => {
    setState(FormSteps.BillingInformation);
  };

  const nextForm = () => {
    if (viewPath == null || viewPath == "") {
      NotificationManager.warning(t("files.validations.conditions"));
      return;
    }
    const uId = getUserInfo().id;
    const submitData = {
      uId: uId,
      state: FormSteps.UploadFiles,
      postulationState: postulationState,
    };
    callApi(() => changeState(submitData));
    NotificationManager.success(t("form.success"));
    setState(FormSteps.UploadFiles);
  };

  const userId = {
    userId: userInfo.id,
  };

  const defaultValues = {};
  const methods = useForm({ defaultValues });

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const validations = {
    file_type_id: {
      required: {
        value: true,
        message: t("validationError.file_type_id.require"),
      },
    },
    file: {
      required: {
        value: true,
        message: t("image.requireImage"),
      },
    },
  };

  const getFileUrl = () => {
    switch (courseId) {
      case "9":
        return "/download/GeneralConditions/SUMMER-Work & Travel 2025-26.pdf";
      case "5":
      case "10":
        return "/download/GeneralConditions/WINTER-Work & Travel 2025-26.pdf";
      default:
        return "/download/GeneralConditions/CondicionesGenerales_Work &Travel2024_25.pdf";
    }
  };

  const onSubmit = handleSubmit((data) => {
    var formData = new FormData();
    formData.append("userId", userInfo.id);
    formData.append("image", data.image[0]);
    formData.append("courseId", courseId);
    formData.append("file_type_id", 4);

    if (
      document.find(
        (element) =>
          element.file_type_id == data.file_type_id &&
          data.file_type_id == FileTypeId.GeneralConditions
      )
    ) {
      NotificationManager.warning(t("files.validations.duplicated"));
      return;
    } else {
      setBotonActivo(true);
      callApi(
        () => uploadImages(formData),
        (data) => {
          setViewPath(data.path);
          NotificationManager.success(t("template.uploadSucces"));
          loadData(userId);
          handleClose();
        }
      );
    }
    setBotonActivo(false);
  });

  const loadData = (userId) => {
    callApi(
      () => getConditions(userId),
      (data) => {
        setCourseId(data.courseId);
        ageResolve(data.dateBirth);
        if (data.path != null) {
          setViewPath(data.path);
        }
      }
    );
  };

  useEffect(() => {
    loadData(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanInputs = () => {
    setValue("file_type_id", "");
    setValue("file", "");
  };

  return (
    <>
      <h2 className="title">{t("form.program") + programName}</h2>
      <h2>{t("form.conditions")}</h2>
      <div>
        <div>
          <table>
            <tbody>
              <tr>
                <td>
                  <h4>{t("form.conditionsOpt1")}</h4>
                </td>
              </tr>
              <tr>
                <td>{t("uploadFiles.message.generalConditions")}</td>
                <td>
                  <Button
                    label={t("uploadFiles.upload")}
                    onClick={() => {
                      setBotonActivo(false);
                      cleanInputs();
                      handleOpen();
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <h4>{t("form.conditionsOpt2")}</h4>
                </td>
              </tr>
              <tr>
                <td>{t("uploadFiles.message.generalConditionsSign")}</td>
                <td>
                  <Button
                    label={t("main.sign")}
                    onClick={() => {
                      setSignModal(true);
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        {viewPath && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                label={
                  <>
                    <AiFillEye style={{ marginRight: "5px" }} />
                    {t("postulation.previewConditions")}
                  </>
                }
                onClick={() => {
                  setPreviewModal(true);
                }}
              />
            </div>
          </>
        )}
      </div>
      <br />
      <div className="container-inputs container-inputs-topmargen">
        <Button
          size="btn-sx"
          type="ww-btn-secondary"
          label={t("main.back")}
          onClick={() => backForm()}
        />
        <Button
          size="btn-sx"
          onClick={() => nextForm(document)}
          label={t("main.next")}
        />
      </div>
      {/* ModalAdjuntar */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainContainer class="ww-container-m">
          <br />
          <FormProvider {...methods}>
            <form encType="multipart/form-data" className="form-m">
              <a
                href={getFileUrl()}
                download="Condiciones Generales-Work & Travel 2024-25"
                target="_blank"
              >
                {t("form.download")}
              </a>
              <div className="mb-3">
                <input
                  className="form-control"
                  type="file"
                  id="image"
                  name="image"
                  {...register("image", validations.file)}
                />
                <span
                  style={{ margin: "10px 0 0 0" }}
                  className="text-danger text-small d-block mb-2"
                >
                  {errors.image && errors.image.message}
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <Button
                  type="ww-btn-secondary"
                  size="btn-s"
                  label={t("main.cancel")}
                  onClick={() => handleClose()}
                  disabled={botonActivo}
                />
                <Button
                  size="btn-s"
                  label={t("uploadFiles.upload")}
                  onClick={onSubmit}
                  disabled={botonActivo}
                />
              </div>
            </form>
          </FormProvider>
        </MainContainer>
      </Modal>
      {/* Modal Firmar */}
      <Modal
        open={signModal}
        onClose={handleCloseSign}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-m" Nologo={true}>
          <p>{t("form.previewView")}</p>

          {isAndroid ? (
            <a href={getFileUrl()} download>
              <Button size="btn-m" label={t("form.download")} />
            </a>
          ) : (
            <embed src={getFileUrl()} className="file-preview-view" />
          )}
          <SignConditionsCanvas
            setSignBtn={setState}
            courseId={courseId}
            age={age}
            onClose={handleCloseSign}
            setViewPath={setViewPath}
          />
        </MainContainer>
      </Modal>
      {/* Modal Preview */}
      <Modal
        open={previewView}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ overflowY: "auto" }}
      >
        <MainContainer class="ww-container-m" Nologo={true}>
          <p>{t("form.previewView")}</p>
          {viewPath != "" && (
            <>
              <embed
                src={urlBase + "/" + viewPath.replace("WorldWideFiles", "")}
                className="file-preview-view"
              />
            </>
          )}
          <div>
            <Button
              style={{ margin: "10px" }}
              size="btn-s secondary"
              label={t("main.close")}
              onClick={() => setPreviewModal(false)}
            />
          </div>
        </MainContainer>
      </Modal>
    </>
  );
};

export default GeneralConditions;

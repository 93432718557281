import React from "react";
import { useTranslation } from "react-i18next";
import FilesTable from "../../components/filesTable";
import { typeFiles } from "../../constants/enum";

const InterviewConsular = () => {
  const { t } = useTranslation();
  const files = [
    {
      name: "material.instructions",
      description: "material.instructionsDescription",
      url: "/download/GeneralDocuments/Instructivo página web WORLDWIDE.pdf",
    },
    {
      name: "Documentos que debes conocer",
      description: "help.interviewConsular.documents",
      url: "/download/Documentos que debes conocer.pdf",
    },
    {
      name: "Checklist 2024",
      description: "help.interviewConsular.check",
      url: "/download/Checklist 2024.pdf",
    },
    {
      name: "Ejemplo hoja de confirmación DS 160",
      description: "help.interviewConsular.exampleDs160",
      url: "/download/EJEMPLO HOJA DE CONFIRMACIÓN DS-160.pdf",
    },
    {
      name: "Carta SWT 2024",
      description: "help.interviewConsular.letterSWT",
      url: "/download/Carta Swt 2024.pdf",
    },
    {
      name: "Folleto Wilbeforce",
      description: "help.interviewConsular.wilbeforce",
      url: "/download/FOLLETO Wilberforce-ENG-100116.pdf",
    },
    {
      name: "Nota autorización DHL",
      description: "help.interviewConsular.dhl",
      url: "/download/NOTA AUTORIZACION DHL.pdf",
    },
    {
      name: "Cómo completar el formulario DS-160 y cómo sacar un turno con la embajada de EE.UU",
      description: "help.interviewConsular.videoDS160",
      url: "https://worldwideturar-my.sharepoint.com/:v:/g/personal/aperalta_worldwidece_com/EbXStL5mpGhEk_kRmHfg7kgBQvT_i6ejNptSbudgTFsu5Q?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0NvcHkifX0&e=Xf0Der",
      type: typeFiles.video,
    },
    {
      name: "Capacitación pre embajada (cas+ cita consular)",
      description: "help.interviewConsular.preEmbassy",
      url: "https://worldwideturar-my.sharepoint.com/:v:/g/personal/aperalta_worldwidece_com/EfZ4nDFIiStJrtoaJ90vuVUBZbz369lWTpmamLuKqPpUSg?nav=eyJyZWZlcnJhbEluZm8iOnsicmVmZXJyYWxBcHAiOiJPbmVEcml2ZUZvckJ1c2luZXNzIiwicmVmZXJyYWxBcHBQbGF0Zm9ybSI6IldlYiIsInJlZmVycmFsTW9kZSI6InZpZXciLCJyZWZlcnJhbFZpZXciOiJNeUZpbGVzTGlua0NvcHkifX0&e=JslfsW",
      type: typeFiles.video,
    },
  ];
  return (
    <>
      <h2 className="title">{t("material.interviewConsular")}</h2>
      <FilesTable files={files} />
    </>
  );
};

export default InterviewConsular;
